
.inp{
    border: none;
    border-bottom: 1px solid black;
    width: 100%;

}


.icon{
    font-size: 20px;
}
